const commonRules = [
  // if it says it's a webview, let's go with that
  'WebView',
]

const iosRules = [
  // iOS webview will be the same as safari but missing "Safari"
  '(iPhone|iPod|iPad)(?!.*Safari)',
]

const androidRules = [
  '\/webview$',
]

const webviewRegExp = new RegExp('(' + [ ...commonRules, ...iosRules, ...androidRules ].join('|') + ')', 'ig')
const iosRegExp = new RegExp('(' + [ ...commonRules, ...iosRules ].join('|') + ')', 'ig')
const androidRegExp = new RegExp('(' + [ ...commonRules, ...androidRules ].join('|') + ')', 'ig')

const checkWebView = (userAgent) => {
  const isWebView = userAgent.match(webviewRegExp)
  const isAndroid = userAgent.match(androidRegExp) // FYI may not work on some versions because Android has no universal way to identify webview on client by UA
  const isIos = userAgent.match(iosRegExp)

  return { isWebView, isIos, isAndroid }
}

export default checkWebView