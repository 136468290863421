const formatSpaces = (priceString) => {
  const result = ([ ...priceString ]).reverse()
    .map((char, index) => (
      index % 3 === 2 ? ` ${char}` : char
    ))
    .reverse()
    .join('')

  return result.replace(/^ /, '')
}

const formatPrice = (value = 0, opts?: { withCurrency?: boolean, toFixed?: number }): string => {
  const { withCurrency = true, toFixed = 2 } = opts || {}

  const modifier = value < 0 ? '- ' : ''
  const [ price, cents ] = (Math.abs(value) / 100).toFixed(toFixed).split('.')
  const currency = withCurrency ? ' ₽' : ''

  const formattedValue = `${formatSpaces(price)}.${cents}`

  return `${modifier}${formattedValue}${currency}`
}


export default formatPrice
