import { RefObject } from 'react'


export const wait = (number: number) => new Promise((resolve) => setTimeout(resolve, number))

const resolveRef = async (ref: RefObject<any>, count = 0) => {
  if (ref.current) {
    return Promise.resolve()
  }
  else if (count < 10) {
    await wait(10 * count)
    return Promise.resolve(resolveRef(ref, count + 1))
  }
  else if (count < 20) {
    await wait(100 * count)
    return Promise.resolve(resolveRef(ref, count + 1))
  }
  else {
    console.error('Can\'t resolve refs')

    return Promise.reject()
  }
}


export default resolveRef
