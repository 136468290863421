import L from 'leaflet'


type Props = {
  placemarks?: { geometry?: [ number, number ] }[]
  polylines?: { geometry?: [ number, number ][] }[]
  multiPolygons?: { geometries?: [ number, number ][] }[]
}

const getMapBounds = ({ placemarks = [], polylines = [], multiPolygons = [] }: Props): L.LatLngBoundsLiteral => ([
  ...Array.isArray(placemarks) ? placemarks.map(({ geometry }) => geometry) : [],
  ...Array.isArray(polylines) ? polylines.map(({ geometry }) => geometry).flat(1) : [],
  ...Array.isArray(multiPolygons) ? multiPolygons.map(({ geometries }) => geometries).flat(1) : [],
])

export default getMapBounds