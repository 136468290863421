/**
 * Transforms multiline string to one liner and trims it
 * e.g. '  this\n is the text    ' → 'this is the text'
 */
const multiLineToOneLine = (string: string): string =>
  string.replace(/\n\s*|\s{2,}/mg, ' ').trim()

/**
 * Capitalizes, transforms first letter to uppercase, string
 * e.g. dumbo → Dumbo
 */
const capitalize = (string: string): string => string.charAt(0).toUpperCase() + string.slice(1)

/**
 * Decapitalizes, transforms first letter to lowercase, string
 * e.g. CamelCase -> camelCase
 */
const decapitalize = (string: string): string =>
  string.split('').map((l, i) => i === 0 ? l.toLowerCase() : l).join('')


export default {
  multiLineToOneLine,
  decapitalize,
  capitalize,
}
