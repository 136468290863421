import dateModule from 'date'
import { Field } from 'formular'

// Regular expressions
const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ // eslint-disable-line

const ipRegex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
const ipRangeRegex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?) ?- ?(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/ // eslint-disable-line

// Helpers
const isEmpty = (value: any): boolean => (
  typeof value === 'undefined'
  || value === null
  || value === ''
  || /^\s+$/.test(value)
)

const isValueLengthLessThan = (length: number, value: string | number): boolean => (
  !isEmpty(value) && String(value).trim().length < length
)

const isValueLengthLongerThan = (length: number, value: string | number): boolean => (
  !isEmpty(value) && String(value).trim().length > length
)

const isDateNotValid = (value: string): boolean => (
  !isEmpty(value) && !dateModule(value).isValid()
)

const isDateFromFuture = (value: string): boolean => (
  !isEmpty(value) && dateModule(value).isAfter(new Date())
)

const isDateToday = (value: string): boolean => (
  !isEmpty(value) && dateModule(value).diff(new Date(), 'day') === 0
)

export const isFieldValueNotEqual = (fieldName: string) => (
  (value: string, values: object): boolean  => {
    const otherValue = values[fieldName]?.state?.value

    if (value && otherValue) {
      return value !== otherValue
    }
  }
)


// Validators
export const required = (value: any) => {
  if (isEmpty(value)) {
    return 'Введите значение'
  }
}

export const minLength = (length: number) => (value: string | number) => {
  if (isValueLengthLessThan(length, value)) {
    return `Минимальная длина ${length}`
  }
}

export const maxLength = (length: number) => (value: string | number) => {
  if (isValueLengthLongerThan(length, value)) {
    return `Максимальная длина ${length}`
  }
}

export const email = (value: string) => {
  if (!emailRegex.test(value)) {
    return `Введите корректный email`
  }
}

export const pastDate = (value: string) => {
  if (isDateNotValid(value)) {
    return 'Дата'
  }

  if (isDateFromFuture(value)) {
    return 'Дата прошлое'
  }
}

export const notToday = (value: string) => {
  if (isDateNotValid(value)) {
    return 'Дата'
  }

  if (isDateToday(value)) {
    return 'Не сегодня'
  }
}

export const date = (value: string) => {
  if (isDateNotValid(value)) {
    return 'Дата'
  }
}

export const equal = (fieldName: string, errorMessage: string) => (
  (value: string, values: object) => {
    if (isFieldValueNotEqual(fieldName)(value, values)) {
      return errorMessage
    }
  }
)

export const isNumeric = (value: string) => {
  if (value === '') {
    return 'Введите число' // outstanding case because Number('') is 0
  }

  const numberValue = Number(value.replaceAll(',', '.'))

  if (isNaN(numberValue)) {
    return 'Введите число'
  }
}

// '10.0.0.1' or '10.0.0.1 - 10.0.0.45'
export const ipOrIpRange = (value: string) => {
  if (!ipRegex.test(value) && !ipRangeRegex.test(value)) {
    return 'Введите IP-адрес, либо диапазон IP-адресов в формате "10.0.0.1", либо "10.0.0.1 - 10.0.0.45"'
  }
}

export const isPhoneNumber = (value: string) => {
  if (!value?.length) {
    return
  }
  if (/(?![+\-() 0-9])./.test(value)) {
    return 'Допустимые символы: "+-()", а также цифры и пробел'
  } else {
    const trimmed = value.replaceAll(/[+ ()-]/g, '')

    if (trimmed.length !== 11) {
      return 'Длина номера должна составлять 11 символов'
    }
  }
}

type FormFields = Record<string, Field<string>>

type CompareDateProps = {
  moreThan?: string
  lessThan?: string
}

export const compareDate = ({ moreThan, lessThan }: CompareDateProps) => (value: string, fields: FormFields) => {
  const fieldName = moreThan || lessThan
  const valueToCompare = fields[fieldName as string]?.state.value

  if (value && valueToCompare) {
    const date = new Date(value)
    const compareDate = new Date(valueToCompare)
    const compareMore = Boolean(moreThan)

    const isError = compareMore
      ? date <= compareDate
      : date >= compareDate

    if (isError) {
      return compareMore
        ? `Должно быть больше ${valueToCompare}`
        : `Должно быть меньше ${valueToCompare}`
    }
  }
}
