const deepClone = <T>(object: T): T => JSON.parse(JSON.stringify(object))

const filterEmptyStringValues = (object: Object) => Object.fromEntries(Object.entries(object).filter(([ key, value ]) => value !== ''))

const filterFalsyValues = (object: Object) => Object.fromEntries(Object.entries(object).filter(([ key, value ]) => Boolean(value)))

export default {
  deepClone,
  filterEmptyStringValues,
  filterFalsyValues,
}