// Smart search - will return match even if the word wrote with typo
// Usage:
// arr.filter((text) => wordMatch(text, searchText) <= 0.6)

const wordMatch = (left: string, right: string): number => {
  if (left === undefined || right === undefined) {
    return 1
  }
  left = left.toLowerCase()
  right = right.toLowerCase()

  if (left.includes(right)) {
    return 0
  }

  // 0 - full match
  // 1 - no match
  left = '\b\b' + left.toLowerCase() + '\f\f'
  right = '\b\b' + right.toLowerCase() + '\f\f'

  let dist = -4

  for (let i = 0; i < left.length - 2; ++i) {
    if (!right.includes(left.slice(i, i + 3))) ++dist
  }

  for (let i = 0; i < right.length - 2; ++i) {
    if (!left.includes(right.slice(i, i + 3))) ++dist
  }

  return Math.max(0, dist) / (left.length + right.length - 8)
}


export default wordMatch
