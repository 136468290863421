/**
 * Transforms number
 * 1000 => 1 000
 * 10000 => 10 000
 * 100000 => 100 000
 * 1000000 => 100 000
 * 1000000 => 1 000 000
 */
const format = (number: string | number): string =>
  String(number).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')


export default {
  format,
}
